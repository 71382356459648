<template>
  <section>
      <div class="content-header">
        <h2>Take a deep dive into positive psychology</h2>
      </div>
      <div class="content-wrapper">
        <p>Featuring New York Times bestsellers, popular Ted Talks and free guided meditations, this <a href="https://learn.library.ryerson.ca/MentalHealth/ThriveRU" target="_blank">extensive catalogue</a> will introduce students to some of the most well-known thinkers in the field of positive psychology.</p>
        <!-- <img src="@/assets/img/_content/personal-review-1.png" class="img-fluid img-thumb" alt="" srcset=""> -->
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
